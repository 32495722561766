import { type SEOHandle } from '@nasa-gcn/remix-seo';
import {
  redirect,
  type LoaderFunctionArgs,
  type MetaFunction,
} from '@remix-run/node';
import {
  Link,
  json,
  useFetcher,
  useLoaderData,
  type ClientLoaderFunctionArgs,
  type ClientActionFunctionArgs,
} from '@remix-run/react';
import { Pencil, PlusCircle, Trash2 } from 'lucide-react';
import { toast } from 'sonner';
import { z } from 'zod';
import { NewProjectDialog } from '#app/components/new-project-dialog.tsx';
import { Button } from '#app/components/ui/button.tsx';
import {
  Table,
  TableBody,
  TableCaption,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '#app/components/ui/table.tsx';
import { getUserId } from '#app/utils/auth.server.ts';
import { ProjectSchema, type ProjectSchemaModel } from '#app/utils/sandpack.ts';

export async function loader({ request }: LoaderFunctionArgs) {
  if (await getUserId(request)) throw redirect('/dashboard');
  return null;
}

export async function clientLoader({ serverLoader }: ClientLoaderFunctionArgs) {
  await serverLoader();

  const localProjects: Array<ProjectSchemaModel> = [];

  for (let i = 0; i < localStorage.length; i++) {
    try {
      const lsKey = localStorage.key(i) ?? '';
      const project = ProjectSchema.parse(
        JSON.parse(localStorage.getItem(lsKey) ?? ''),
      );

      if (project.id === lsKey) {
        localProjects.push(project);
      }
    } catch (error) {
      // TODO: Log error in dev mode
    }
  }

  return json({ localProjects });
}

// Run the client loader
// even during initial hydration
clientLoader.hydrate = true;

const DeleteLocalProjectActionSchema = z.object({
  projectId: z.string(),
});

export async function clientAction({ request }: ClientActionFunctionArgs) {
  try {
    const body = await request.json();
    const { projectId } = DeleteLocalProjectActionSchema.parse(body);
    localStorage.removeItem(projectId);
  } catch (error) {
    toast.error('Failed to delete project');
  }

  return null;
}

export function HydrateFallback() {
  return (
    <HomePage>
      <EmptyState />
    </HomePage>
  );
}

export const meta: MetaFunction = () => {
  return [{ title: 'Home | Kempo' }];
};

export const handle: SEOHandle = {
  getSitemapEntries() {
    return [{ route: '/', priority: 0.7 }];
  },
};

export default function Index() {
  const { localProjects } = useLoaderData<typeof clientLoader>();
  const fetcher = useFetcher();

  const localProjectsTable = (
    <div className="flex flex-col gap-4 p-2">
      <NewProjectDialog
        trigger={
          <Button variant="default" size="sm" className="mt-6 gap-1.5">
            <PlusCircle className="size-3.5" />
            Create New Project
          </Button>
        }
      />

      <Table>
        <TableCaption>Local Projects</TableCaption>
        <TableHeader>
          <TableRow>
            <TableHead>Name</TableHead>
            <TableHead>Template</TableHead>
            <TableHead className="w-[50px]">
              <span className="sr-only">Go to project</span>
            </TableHead>
            <TableHead className="w-[50px]">
              <span className="sr-only">Delete project</span>
            </TableHead>
          </TableRow>
        </TableHeader>
        <TableBody>
          {localProjects.map(({ id, name, core }) => (
            <TableRow key={id}>
              <TableCell>{name}</TableCell>
              <TableCell>{core.template}</TableCell>
              <TableCell>
                <Button asChild variant="ghost" size="sm">
                  <Link to={`/projects/${id}/edit`}>
                    <Pencil className="size-3" />
                    <span className="sr-only">Edit Project {name}</span>
                  </Link>
                </Button>
              </TableCell>
              <TableCell>
                <Button
                  variant="ghost"
                  size="sm"
                  onClick={() => {
                    fetcher.submit(
                      { projectId: id },
                      { method: 'POST', encType: 'application/json' },
                    );
                  }}
                >
                  <Trash2 className="size-3" />
                  <span className="sr-only">Delete Project {name}</span>
                </Button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  );

  return (
    <HomePage>
      {localProjects.length > 0 ? localProjectsTable : <EmptyState />}
    </HomePage>
  );
}

function HomePage(props: React.PropsWithChildren) {
  return (
    <main vaul-drawer-wrapper="" className="grid h-full place-items-center">
      <div className="grid place-items-center px-4 py-16 xl:grid-cols-2 xl:gap-24">
        <div className="flex max-w-md flex-col items-center text-center xl:order-2 xl:items-start xl:text-left">
          <div className="animate-slide-top [animation-fill-mode:backwards] xl:animate-slide-left xl:[animation-delay:0.5s] xl:[animation-fill-mode:backwards]">
            <svg
              className="size-20 text-foreground xl:-mt-4"
              width="24px"
              height="24px"
              strokeWidth="1.5"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M13.5 6L10 18.5"
                className="stroke-foreground"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              ></path>
              <path
                d="M6.5 8.5L3 12L6.5 15.5"
                className="stroke-foreground"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              ></path>
              <path
                d="M17.5 8.5L21 12L17.5 15.5"
                className="stroke-foreground"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              ></path>
            </svg>
          </div>
          <h1
            data-heading
            className="mt-8 animate-slide-top font-showcase text-4xl font-medium text-foreground [animation-delay:0.3s] [animation-fill-mode:backwards] md:text-5xl xl:mt-4 xl:animate-slide-left xl:text-6xl xl:[animation-delay:0.8s] xl:[animation-fill-mode:backwards]"
          >
            Kempo
          </h1>
          <p
            data-paragraph
            className="mt-6 animate-slide-top font-showcase text-xl/7 text-muted-foreground [animation-delay:0.6s] [animation-fill-mode:backwards] xl:mt-8 xl:animate-slide-left xl:text-xl/6 xl:leading-10 xl:[animation-delay:1s] xl:[animation-fill-mode:backwards]"
          >
            Empowering developers to build tailor-made code playgrounds to
            supercharge blogs, docs, or other teaching tools.{' '}
          </p>
        </div>

        <div className="mt-16 max-h-[400px] w-full animate-slide-top overflow-auto [animation-delay:0.8s] [animation-fill-mode:backwards] xl:mt-0 xl:[animation-delay:1.2s]">
          {props.children}
        </div>
      </div>
    </main>
  );
}

function EmptyState() {
  return (
    <div className="flex h-[400px] flex-1 items-center justify-center rounded-lg border border-dashed shadow-sm">
      <div className="flex flex-col items-center gap-1 text-center">
        <h3 className="text-2xl font-bold tracking-tight">
          You have no projects
        </h3>
        <p className="text-sm text-muted-foreground">
          Local projects will show up here as soon as you create one.
        </p>
        <NewProjectDialog
          trigger={
            <Button variant="default" size="sm" className="mt-6">
              Start building for free
            </Button>
          }
        />
      </div>
    </div>
  );
}
